import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import buttonIcon from './images/onlyfans.jpg';
import buttonIcon2 from './images/clear.png';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };





useEffect(() => {
  const fetchLocationData = () => {
    fetch('/api/location')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCountry(decodeURIComponent(data.country));
        setCity(decodeURIComponent(data.city));
        
        // Remove numbers from city and country names
        const cleanCity = data.city.replace(/[0-9]/g, '');
        const cleanCountry = data.country.replace(/[0-9]/g, '');

        const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
        console.log("URL Requested: ", url); // Log the URL
        return axios.get(url);
      })
      .then(response => {
        console.log("Google Geocoding API Response: ", response.data); // Log the full response
        const results = response.data.results;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
          if (stateObj) {
            setRegion(stateObj.long_name);
          }
        }
        setFetchAttempted(true);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setCountry('Unavailable');
        setRegion('');
        setCity('');
        setFetchAttempted(true);
      });
  };

  if (!region && !fetchAttempted) {
    fetchLocationData();
  }
}, [region]); // Dependency on region

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const handleResize = () => {
      const pcWidth = 1024; // Define the minimum width for a PC screen
      if (window.innerWidth >= pcWidth) {
        window.location.href = 'https://onlyfans.com/jergambino/c1'; // Redirect URL for desktop
      }
    };

    handleResize(); // Call on mount to check immediately
    window.addEventListener('resize', handleResize); // Add listener for any resize events

    return () => window.removeEventListener('resize', handleResize); // Clean up listener
  }, []);


  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="landing-container">
      <video
        autoPlay // Start playing the video as soon as it's ready
        playsInline // This attribute is important for playing the video inline on iOS devices
        preload="auto" // Suggests to the browser that it should start downloading the video as soon as possible
        loop // Keeps the video looping
        controls // Add this if you want to show controls like play, pause, volume, etc.
        style={{
          position: "absolute",
          width: "100%",
          height: "125%", // Increase the height to cover the extra space when the video is moved up
          top: "-25vh", // Move the video up by 25% of the viewport height
          left: 0,
          objectFit: "cover",
          zIndex: 1
        }}
      >
        <source src="./images/background2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="fade-section">
        <div className="top-fade-text"><strong>J. Gambino</strong></div> 
        <div className="mid-fade-text">Ordinary guy trying to have some fun<br/>
        
</div> 
        

      </div>
      <div className="text-section">
  <a href="https://onlyfans.com/jergambino/c1" className="custom-button" aria-label="Onlyfans">
    <div className="button-content">
      <div className="button-icon">
        <img src={buttonIcon} alt="link" />
      </div>
      <div className="button-text">
        <div>Onlyfans<br/>Subscribe at your own risk. I'm a bit of a freak when it comes to toys 🔥</div>
      </div>
      <div className="button-icon">
        <img src={buttonIcon2} alt="link" />
      </div>
    </div>
  </a>
  <div className="distance-container">
    <img src="../images/location.svg" alt="Location icon" className="location-icon"/>
    <span className="distance-text"><strong>2.4 miles away</strong></span>
  </div>
</div>

      <Analytics/>
    </div>


} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/issyy98" element={<G />} />
<Route path="/onlyfans.com/issyy98" element={<G />} />
<Route path="/MeetUps-Only/onlyfans.com/issyy98" element={<G />} />
<Route path="/Collaborations-Only/onlyfans.com/issyy98" element={<G />} />
</Routes>
</Router>


  );
}

export default App;
